import { SessionApiImpl } from "@adora/backend-api";
import { deepEqual } from "fast-equals";
import Cookies from "js-cookie";
import { uuidv7 } from "uuidv7";
import { AdoraSessionStorageImpl, SessionType } from "./adora-session-storage";
import { debugLog } from "./debug";
import { setupSession } from "./setup-session";
import { WindowSessionStorage } from "./storage";
import { urlAccepted } from "./url-accepted";
const WINDOW_KEY = "adoraIsRunning";
const COOKIE_ADORA_USER_ID_KEY = "_adora_user_id";
const COOKIE_RATE_LIMIT_KEY = "_adora_rate_limited";
let adoraStartResult;
// if adoraStart is called on a blocked url to start with we add a mutation observer
// to listen to it going to an allowed url and then start.
let blockedStartUrlMutationObserver = undefined;
let startedState = "stopped";
const startRecording = async () => {
    debugLog("startRecording", `adoraIsRunning: ${window[WINDOW_KEY]}`);
    try {
        const bundle = await import("./start-recording");
        debugLog("start recording bundle loaded", { startedState });
        if (startedState !== "started") {
            return;
        }
        adoraStartResult = bundle.startRecording({
            sessionType: SessionType.Script,
        });
    }
    catch (e) {
        debugLog("start recording bundle load error", e);
    }
};
// 30 minutes
const RATE_LIMIT_RETRY_DURATION_MS = 1000 * 60 * 30;
const adoraStart = async (settings) => {
    startedState = "started";
    debugLog("adora start", {
        adoraIsRunning: typeof window.sessionStorage === "object" && window[WINDOW_KEY],
    });
    // Guard against SSR and websites running our snippet outside a browser context
    if (typeof window.sessionStorage !== "object") {
        return;
    }
    const storage = new AdoraSessionStorageImpl(SessionType.Script, new WindowSessionStorage());
    const session = storage.getSession();
    // Overwrite uid + user cohorts.
    if (session) {
        let writeChanges = false;
        if (settings.uid && session.settings.uid !== settings.uid) {
            debugLog("detected uid change");
            session.settings.uid = settings.uid;
            storage.setSession(session);
            writeChanges = true;
        }
        // Any user cohort change will be passed into existing session
        if (settings.userCohorts && Object.keys(settings.userCohorts).length > 0) {
            debugLog("detected user cohort change");
            const lastUserCohorts = structuredClone(session.settings.userCohorts);
            if (!deepEqual(lastUserCohorts, settings.userCohorts)) {
                session.settings.userCohorts = settings.userCohorts;
                storage.setSession(session);
            }
            writeChanges = true;
        }
        if (writeChanges &&
            adoraStartResult?.recorder &&
            adoraStartResult?.recorder.isRecording) {
            debugLog("writing cohort change to recorder");
            adoraStartResult?.recorder.recordChangeCustomCohort({
                uid: session.settings.uid,
                userCohorts: session.settings.userCohorts
                    ? {
                        ...session.settings.userCohorts,
                    }
                    : {},
            });
        }
    }
    if (window[WINDOW_KEY] === true) {
        return;
    }
    window[WINDOW_KEY] = true;
    const lastRateLimitedTimestamp = storage.lastRateLimitedTimestamp;
    if (lastRateLimitedTimestamp !== undefined) {
        // If it has been greater than x duration since we last got rate limited then try again, otherwise return
        if (Date.now() - lastRateLimitedTimestamp < RATE_LIMIT_RETRY_DURATION_MS) {
            debugLog("exiting as the session has been rate limited recently");
            return;
        }
        storage.clearLastRateLimitedTimestamp();
    }
    if (Cookies.get(COOKIE_RATE_LIMIT_KEY)) {
        debugLog("exiting as the domain has been rate limited recently");
        return;
    }
    if (session) {
        debugLog("start recording for existing session");
        return startRecording();
    }
    let started = false;
    const start = async (cookieDomain, adoraUserId) => {
        debugLog("start", { started });
        // Ensure starting is idempotent (start is called only once).
        if (started) {
            return;
        }
        started = true;
        const result = await setupSession(SessionType.Script, adoraUserId, settings);
        if (result.type === "error") {
            if (result.code === "RateLimitExceeded") {
                debugLog(`setting expiration on local storage`);
                storage.setLastRateLimitedTimestamp(Date.now());
                if (cookieDomain) {
                    debugLog(`setting expiration cookie`);
                    Cookies.set(COOKIE_RATE_LIMIT_KEY, cookieDomain, {
                        expires: new Date(Date.now() + RATE_LIMIT_RETRY_DURATION_MS),
                        domain: cookieDomain,
                    });
                }
            }
            return;
        }
        return startRecording();
    };
    debugLog("launching session preload");
    try {
        const preload = await new SessionApiImpl(settings.backendUrl).sessionPreload({
            orgId: settings.orgId,
        });
        debugLog("session preload resolved");
        let previousUrl = window.location.href;
        if (preload) {
            if (preload.error) {
                debugLog("error preloading", preload.error);
                return;
            }
            if ("scheduledDowntime" in preload.data) {
                debugLog("scheduled downtime; exiting");
                return;
            }
            const { organization } = preload.data;
            debugLog("session preload success");
            let adoraUserId = Cookies.get(COOKIE_ADORA_USER_ID_KEY);
            let cookieDomain = undefined;
            debugLog(`existing adora user id cookie:`, adoraUserId);
            for (const domain of organization.cookieDomains) {
                if (window.location.hostname.toLowerCase() === domain ||
                    window.location.hostname.toLowerCase().endsWith(`.` + domain)) {
                    cookieDomain = domain;
                    if (!adoraUserId) {
                        adoraUserId = uuidv7();
                        Cookies.set(COOKIE_ADORA_USER_ID_KEY, adoraUserId, {
                            domain,
                            expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
                        });
                        debugLog(`setting adoraUserId cookie on domain`, adoraUserId);
                    }
                }
            }
            debugLog(`using adora user id:`, adoraUserId);
            if (urlAccepted({
                urlStr: previousUrl,
                blockedPaths: organization.blockedPaths,
                allowedPaths: organization.allowedPaths,
                pathConfig: organization.pathConfig,
            })) {
                debugLog("at an unblocked URL, starting adora");
                await start(cookieDomain, adoraUserId);
            }
            else {
                debugLog("at an blocked URL, waiting for unblocked URL to start adora");
                if (blockedStartUrlMutationObserver) {
                    blockedStartUrlMutationObserver.disconnect();
                }
                // Listen for mutations until we are at an accepted URL.
                blockedStartUrlMutationObserver = new MutationObserver(function (_, observer) {
                    if (previousUrl != window.location.href) {
                        const thisHref = window.location.href;
                        const accepted = urlAccepted({
                            urlStr: thisHref,
                            blockedPaths: organization.blockedPaths,
                            allowedPaths: organization.allowedPaths,
                            pathConfig: organization.pathConfig,
                        });
                        debugLog(`url changed detected during blocked state, status: ${!!accepted}`);
                        if (accepted) {
                            debugLog(`disconnecting observer`);
                            observer.disconnect();
                            debugLog(`scheduling adora to start`);
                            start(cookieDomain, adoraUserId);
                            return;
                        }
                        previousUrl = thisHref;
                    }
                });
                blockedStartUrlMutationObserver.observe(document, {
                    subtree: true,
                    childList: true,
                });
            }
        }
    }
    catch (error) {
        debugLog("Error in adoraStart:", error);
    }
};
const adoraStop = async () => {
    startedState = "stopped";
    debugLog("adora stop", {
        adoraIsRunning: typeof window.sessionStorage === "object" && window[WINDOW_KEY],
    });
    if (adoraStartResult) {
        adoraStartResult.recorder.setRecording(false);
        adoraStartResult.recorder.stopFutureRecordings();
        adoraStartResult.urlChangeObserverDispose();
        await adoraStartResult.eventSync.clearAndSaveEvents();
    }
    blockedStartUrlMutationObserver?.disconnect();
    window[WINDOW_KEY] = false;
    adoraStartResult = undefined;
};
window.adoraStart = adoraStart;
window.adoraStop = adoraStop;
