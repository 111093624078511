import { matchURLAggregationRule } from "@adora/backend-api";
import { unreachable } from "@adora/lib/src/utils/unreachable";
/**
 * Normalizes domain patterns by removing protocols and trailing slashes.
 * This ensures that "https://example.com" matches with "example.com".
 */
function normalizeDomainPattern(domain) {
    // Remove protocol if present
    if (domain.startsWith("http://") || domain.startsWith("https://")) {
        domain = domain.replace(/^https?:\/\//, "");
    }
    // Remove trailing slash if present
    if (domain.endsWith("/")) {
        domain = domain.substring(0, domain.length - 1);
    }
    // Remove port if present
    domain = domain.replace(/:\d+$/, "");
    return domain;
}
/**
 * Simple domain matching function that handles exact matches and global wildcard.
 * Supports patterns:
 * - Exact matches: "example.com"
 * - Wildcard for any domain: "*"
 * - URLs with protocols: "https://example.com"
 */
function matchesDomain(hostname, domainPattern) {
    if (!domainPattern || domainPattern === "") {
        return false;
    }
    if (domainPattern === "*") {
        return true;
    }
    const normalizedPattern = normalizeDomainPattern(domainPattern);
    return hostname === normalizedPattern;
}
/**
 * Checks if a URL should be recorded.
 * Returns the URL if allowed (possibly redacted).
 * Returns undefined if the URL is blocked.
 */
export function urlAccepted({ urlStr, blockedPaths, allowedPaths, pathConfig, }) {
    const url = new URL(urlStr);
    const pathname = url.pathname;
    const hostname = url.hostname;
    // TODO: introduce wildcards for domain matching later
    const domainRules = pathConfig.rules.filter((rule) => matchesDomain(hostname, rule.domain));
    if (domainRules.length > 0) {
        // Check if we have a base rule (path="**")
        const baseRule = domainRules.find((rule) => rule.path === "**");
        if (!baseRule) {
            // No base rule for domain, fall back to default action
            return pathConfig.defaultAction === "allow" ? url : undefined;
        }
        // Look for any exception rules that match the path
        for (const rule of domainRules) {
            if (rule.path === "**")
                continue; // Skip base rule
            const match = matchURLAggregationRule(pathname, rule);
            if (match !== undefined) {
                switch (rule.action) {
                    case "allow":
                        return url;
                    case "block":
                        return undefined;
                    case "replace":
                        url.pathname = match;
                        return url;
                    default:
                        unreachable(rule.action);
                }
            }
        }
        return baseRule.action === "allow" ? url : undefined;
    }
    // Then check non-domain rules
    const nonDomainRules = pathConfig.rules.filter((rule) => !rule.domain);
    for (const rule of nonDomainRules) {
        const match = matchURLAggregationRule(pathname, rule);
        if (match !== undefined) {
            switch (rule.action) {
                case "allow":
                    return url;
                case "block":
                    return undefined;
                case "replace":
                    url.pathname = match;
                    return url;
                default:
                    unreachable(rule.action);
            }
        }
    }
    // Legacy support; will be removed later
    if (allowedPaths.length > 0) {
        for (const allowedPath of allowedPaths) {
            if (matchURLAggregationRule(pathname, { path: allowedPath }) !== undefined) {
                return url;
            }
        }
    }
    for (const blockedPath of blockedPaths) {
        if (matchURLAggregationRule(pathname, { path: blockedPath }) !== undefined) {
            return undefined;
        }
    }
    return pathConfig.defaultAction === "allow" ? url : undefined;
}
